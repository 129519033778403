var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-auto justify-content-center"},[(_vm.step === 1)?_c('ValidationObserver',{staticClass:"col-lg-12 pt-3"},[_c('el-collapse-transition',[_c('div',{staticClass:"col-12 pl-0 pl-md-4 px-0 pr-0 mt-n2 row mx-auto align-items-start justify-content-center py-2 flex-nowrap"},[(_vm.mobile)?_c('iframe',{attrs:{"id":"af9eb77b","name":"af9eb77b","src":"https://ads.nexoinmobiliario.pe/www/delivery/afr.php?refresh=6&zoneid=58&source=_&target=_blank&cb=INSERT_RANDOM_NUMBER_HERE","frameborder":"0","scrolling":"no","width":"300","height":"75","allowtransparency":"true"}},[_c('a',{attrs:{"href":"https://ads.nexoinmobiliario.pe/www/delivery/ck.php?n=aeba396d&cb=INSERT_RANDOM_NUMBER_HERE","target":"_blank"}},[_c('img',{attrs:{"src":"https://ads.nexoinmobiliario.pe/www/delivery/avw.php?zoneid=58&source=_&cb=INSERT_RANDOM_NUMBER_HERE&n=aeba396d","border":"0","alt":""}})])]):_c('iframe',{attrs:{"id":"aa837d09","name":"aa837d09","src":"https://ads.nexoinmobiliario.pe/www/delivery/afr.php?refresh=6&zoneid=57&source=_&target=_blank&cb=INSERT_RANDOM_NUMBER_HERE","frameborder":"0","scrolling":"no","width":"850","height":"75","allowtransparency":"true"}},[_vm._v("//ads.nexoinmobiliario.pe/www/delivery/avw.php?zoneid=57&source=_&cb=INSERT_RANDOM_NUMBER_HERE&n=add95ebe' border='0' alt='' /> ")])])])],1):_vm._e(),(_vm.step === 1)?_c('ValidationObserver',{staticClass:"col-lg-6 col-xl-4 pt-4",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.firstStep($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"mx-auto row justify-content-center"},[_c('img',{staticClass:"logotipo my-4",attrs:{"src":require("@/assets/img/logotipo-nexoagentes-b.png"),"alt":"register"}})]),_c('h4',{staticClass:"my-0 text-white text-center"},[_vm._v(" Formulario de Inicio de sesión ")]),_c('p',{staticClass:"text-white f-14 text-center"},[_vm._v(" Ingresa tu correo electrónico para que accedas a tu cuenta ")]),(_vm.error)?_c('p',{staticClass:"bg-danger text-white f-13 p-1 text-center"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.link)?_c('p',{staticClass:"bg-danger text-white f-13 p-1 text-center"},[_vm._v(" Ups! No has recibido capacitación. Por favor haz "),_c('router-link',{staticClass:"text-white",attrs:{"to":{ name: 'Register' }}},[_vm._v("CLICK AQUÍ")]),_vm._v(" para que te registres en una capacitación de Nexo Agentes. ")],1):_vm._e(),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"correo electrónico","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Ingrese su correo electrónico","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-end f-13"},[_c('router-link',{staticClass:"text-white",attrs:{"to":{ name: 'SendEmail' }}},[_vm._v("¿Olvidaste tu contraseña?")])],1),_c('div',{staticClass:"d-flex justify-content-center pt-4"},[_c('el-button',{staticClass:"px-5",attrs:{"native-type":"submit","type":"warning","disabled":invalid,"round":""}},[_vm._v("Continuar")])],1),_c('p',{staticClass:"text-white f-13 text-center"},[_vm._v(" No tengo cuenta. "),_c('router-link',{staticClass:"text-white font-weight-bold",attrs:{"to":{ name: 'Register' }}},[_vm._v("Quiero registrarme en un capacitación")])],1)]}}],null,false,3860635677)}):_vm._e(),(_vm.step === 2)?_c('ValidationObserver',{staticClass:"col-lg-6 col-xl-5 pt-5",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.secondStep($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h4',{staticClass:"my-0 text-white text-center"},[_vm._v(" Formulario de Inicio de sesión ")]),_c('p',{staticClass:"f-14 text-white text-center"},[_vm._v(" Muy bien, para que puedas acceder a tu cuenta debes de "+_vm._s(_vm.withoutPassword ? 'registrar' : 'ingresar')+" tu contraseña. ")]),_c('ValidationProvider',{staticClass:"mb-2 line-height-18",attrs:{"name":"contraseña","rules":"required|confirmed:confirmation","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":_vm.withoutPassword
                        ? 'Registrar Contraseña'
                        : 'Ingresar Contraseña',"autofocus":true,"show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(_vm.withoutPassword),expression:"withoutPassword"}],staticClass:"mb-2 line-height-18",attrs:{"name":"mvcs","vid":"confirmation","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(errors[0])?_c('span',{staticClass:"text-white ml-3 f-13 bg-danger px-2 mb-n2"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('el-input',{staticClass:"rounded-pill",attrs:{"placeholder":"Repetir Contraseña","show-password":""},model:{value:(
                    _vm.form[_vm.withoutPassword ? 'confirmation' : 'password']
                ),callback:function ($$v) {_vm.$set(_vm.form, _vm.withoutPassword ? 'confirmation' : 'password', $$v)},expression:"\n                    form[withoutPassword ? 'confirmation' : 'password']\n                "}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-center pt-4"},[_c('el-button',{staticClass:"px-5",attrs:{"disabled":invalid,"native-type":"submit","type":"warning","round":""}},[_vm._v("Acceder a Nexo Agentes")])],1)]}}],null,false,1777944776)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }