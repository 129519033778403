
























































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { DeviceUUID } from 'device-uuid';
import ResizeMixin from '@/mixins/size';

@Component
export default class Login extends Mixins(ResizeMixin) {   
    mobile = true;
    mounted() {
        this.resize();
    }
    resize() {
        this.mobile = window.innerWidth <= 992;
    }

    form: { email: string; password: string; confirmation?: string } = {
        email: '',
        password: ''
    };
    step = 1;
    error = '';
    link = false;

    get withoutPassword() {
        return agentModule.withoutPassword;
    }

    async firstStep() {
        try {
            await agentModule.loginFirstStep(this.form);
            this.step++;
        } catch (error) {
            if (typeof error.mensaje === 'string') {
                if (error.mensaje.match(/recibido/g)) {
                    this.link = true;
                } else {
                    this.error = error.mensaje;
                }
            } else {
                this.$notify.error({
                    title: 'Error',
                    message: 'Hemos tenido algunos inconvenientes'
                });
            }
        }
    }

    async secondStep() {
        try {
            const uuid = new DeviceUUID().get();
            await agentModule.loginSecondStep({ ...this.form, deviceId: uuid });
            await agentModule.getUser();
            if (agentModule.accountTypeIsMissing) {
                this.$router.push({ name: 'AccountType' });
            } else if (agentModule.informationIsMissing) {
                this.$router.push({ name: 'MissingInformation' });
            } else if (this.$route.query.redirectTo) {
                this.$router.push(this.$route.query.redirectTo as string);
            } else {
                this.$router.push({ name: 'Customer' });
            }
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
